import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [2];

export const dictionary = {
		"/": [~3],
		"/breakpoint": [26],
		"/callback": [~25],
		"/(authed)/dash": [~5,[2]],
		"/(authed)/dash/cal": [~20,[2]],
		"/(authed)/dash/cal/old": [~21,[2]],
		"/(authed)/dash/mentors": [~6,[2]],
		"/(authed)/dash/mentors/[userId]": [~7,[2]],
		"/(authed)/dash/mentors/[userId]/availability": [~8,[2]],
		"/(authed)/dash/mentors/[userId]/types": [~9,[2]],
		"/(authed)/dash/sessions/create": [~19,[2]],
		"/(authed)/dash/sessions/[sessionId]": [~16,[2]],
		"/(authed)/dash/sessions/[sessionId]/cancel": [~18,[2]],
		"/(authed)/dash/sessions/[sessionId]/edit": [~17,[2]],
		"/(authed)/dash/types": [~12,[2]],
		"/(authed)/dash/types/add": [~15,[2]],
		"/(authed)/dash/types/delete/[typeId]": [~13,[2]],
		"/(authed)/dash/types/edit/[typeId]": [~14,[2]],
		"/(authed)/dash/users": [~10,[2]],
		"/(authed)/dash/users/set": [~11,[2]],
		"/(authed)/my_sessions": [~22],
		"/(authed)/schedule": [~23],
		"/(authed)/schedule/cancel/[id]": [~24],
		"/status": [4]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';